import {
  Section,
  SectionHeader,
  NavLink,
  CardPicasso,
  Link,
  SectionBackground,
} from "@liberetech/design-system";
import classNames from "classnames";

import ImageCDN from "components/ImageCDN/ImageCDN";
import { useTranslation } from "lib/i18n/client";

import styles from "./HomepageSectionBlog.module.css";

const HomepageSectionBlog: React.FC<HomepageSectionBlogProps> = ({
  background,
  lastPosts,
}) => {
  const { t } = useTranslation("homepage");

  if (lastPosts.length <= 0) {
    return null;
  }

  return (
    <Section background={background || "default"}>
      <div className="grid">
        <SectionHeader
          label={t("lastPosts.label")}
          title={t("lastPosts.title")}
          centered
        />
        <ul className={classNames(styles.lastPosts, "grid")}>
          {lastPosts.map((post, i) => (
            <li className={styles.post} key={i}>
              <NavLink href={post.uri}>
                <CardPicasso
                  elevation={1}
                  key={i}
                  title={post.title}
                  image={
                    <ImageCDN
                      alt={post.title}
                      src={post.featuredImage.node.sourceUrl}
                      sizes="400px"
                      loading="lazy"
                    />
                  }
                />
              </NavLink>
            </li>
          ))}
        </ul>
        <NavLink className={styles.lastPostsShowAll} href="/blog">
          <Link>{t("lastPosts.showAll")}</Link>
        </NavLink>
      </div>
    </Section>
  );
};

type HomepageSectionBlogProps = {
  background?: SectionBackground;
  lastPosts: Array<{
    title: string;
    uri: string;
    featuredImage: {
      node: {
        sourceUrl: string;
      };
    };
  }>;
};

export default HomepageSectionBlog;
