import { NavLink } from "@liberetech/design-system";

import CardDaVinci from "components/CardDaVinci/CardDaVinci";
import CarouselSection from "components/Carousel/CarouselSection";
import { useBrand } from "components/Context/BrandContext";
import ImageCDN from "components/ImageCDN/ImageCDN";
import { useTranslation } from "lib/i18n/client";
import { isAssetPromotionEnabled } from "lib/promotion/promotion";

import styles from "./HomepageSectionDestinations.module.css";

const HomepageSectionDestinations: React.FC<
  HomepageSectionDestinationsProps
> = ({ assets, destinations }) => {
  const brand = useBrand();
  const { t } = useTranslation("homepage");

  return (
    <CarouselSection
      className={styles.destinations}
      label={brand.name}
      title={t("destinations.title", { brand: brand.name })}
    >
      {brand.enabledFeatures?.includes("destinations")
        ? destinations.map((destination, i) => (
            <NavLink
              href={destination.uri}
              className={styles.destination}
              key={i}
            >
              <CardDaVinci
                key={i}
                title={destination.title}
                label={
                  destination.assets.length === 0
                    ? t("destinations.comingSoon")
                    : destination.assets.filter((asset) =>
                          isAssetPromotionEnabled(asset.properties.id, brand),
                        ).length > 0
                      ? t("common:promo.discount")
                      : undefined
                }
                image={
                  <ImageCDN
                    alt={destination.title}
                    src={destination.featuredImage.node.sourceUrl}
                    loading="lazy"
                    sizes="(max-width: 1024px) 400px, 768px"
                  />
                }
              />
            </NavLink>
          ))
        : assets.map((asset, i) => (
            <NavLink href={asset.uri} className={styles.destination} key={i}>
              <CardDaVinci
                key={i}
                title={asset.title}
                label={
                  isAssetPromotionEnabled(asset.properties.id, brand)
                    ? t("common:promo.discount")
                    : undefined
                }
                image={
                  <ImageCDN
                    alt={asset.title}
                    src={asset.featuredImage.node.sourceUrl}
                    loading="lazy"
                    sizes="(max-width: 1024px) 400px, 768px"
                  />
                }
              />
            </NavLink>
          ))}
    </CarouselSection>
  );
};

type HomepageSectionDestinationsProps = {
  destinations: HomepageSectionDestinationsDestination[];
  assets: HomepageSectionDestinationsAssets[];
};

export type HomepageSectionDestinationsDestination = {
  title: string;
  uri: string;
  featuredImage: {
    node: {
      sourceUrl: string;
    };
  };
  assets: Array<{ properties: { id: string } }>;
};

export type HomepageSectionDestinationsAssets = {
  title: string;
  uri: string;
  fullName: string;
  properties: {
    id: string;
    closed: boolean;
  };
  featuredImage: {
    node: {
      sourceUrl: string;
    };
  };
};

export default HomepageSectionDestinations;
