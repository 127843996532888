import { Icon } from "@liberetech/design-system";

import { Homepage } from "lib/data";
import SectionWithOverlappedImages from "pagesComponents/_shared/SectionWithOverlappedImages/SectionWithOverlappedImages";

import styles from "./HomepageSectionExperiences.module.css";

const HomepageSectionExperiences: React.FC<HomepageSectionExperiencesProps> = ({
  homepage,
}) => {
  if (!homepage.experiences) {
    return null;
  }

  return (
    <SectionWithOverlappedImages
      title={homepage.experiences.title}
      label={homepage.experiences.label}
      images={homepage.experiences.images}
      description={
        <div className={styles.experiences}>
          {homepage.experiences.features.map((experience, i) => (
            <div key={i} className={styles.experience}>
              <Icon type="contrast">
                <div dangerouslySetInnerHTML={{ __html: experience.icon }} />
              </Icon>
              <h3 className={styles.experienceTitle}>{experience.title}</h3>
              <p>{experience.description}</p>
            </div>
          ))}
        </div>
      }
      inverted
    />
  );
};

type HomepageSectionExperiencesProps = {
  homepage: Homepage;
};

export default HomepageSectionExperiences;
