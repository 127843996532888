import { HeroFull, heroFullImageSizes } from "@liberetech/design-system";
import { GetServerSideProps } from "next";
import { useRouter } from "next/router";
import React from "react";

import ImageCDN from "components/ImageCDN/ImageCDN";
import SectionSpecialRates from "components/SectionSpecialRates/SectionSpecialRates";
import { BreadcrumbJsonLd, Seo } from "components/Seo/Seo";
import fetchCms from "lib/client/cms";
import { Homepage, homepage } from "lib/data";
import { Trans, useTranslation } from "lib/i18n/client";
import { TrackingContextPage } from "lib/tracking";
import withDirectChannelCommonProps from "lib/withDirectChannelCommonProps";
import SectionAmenities from "pagesComponents/_shared/SectionAmenities/SectionAmenities";
import SectionReviews from "pagesComponents/_shared/SectionReviews/SectionReviews";
import HomepageSectionBlog from "pagesComponents/index/HomepageSectionBlog/HomepageSectionBlog";
import HomepageSectionDestinations, {
  HomepageSectionDestinationsAssets,
  HomepageSectionDestinationsDestination,
} from "pagesComponents/index/HomepageSectionDestinations/HomepageSectionDestinations";
import HomepageSectionExperiences from "pagesComponents/index/HomepageSectionExperiences/HomepageSectionExperiences";
import { DirectChannelCommonProps, Page } from "types";

const HomepageView: React.FC<HomePageProps & DirectChannelCommonProps> = ({
  assets,
  destinations,
  lastPosts,
  brand,
  homepage,
}) => {
  const { t } = useTranslation("homepage");
  const { locale } = useRouter();
  const hasReviews = (homepage.reviews || []).length > 0 && brand.reviews;

  return (
    <>
      <Seo
        title={homepage.seo.title}
        description={homepage.seo.metaDesc}
        titleTemplate="%s"
      />
      <HeroFull
        title={<span dangerouslySetInnerHTML={{ __html: homepage.title }} />}
        subtitle={homepage.subtitle}
        claim={
          <>
            <svg viewBox="0 0 24 24">
              <path d="M3.75 12h.75c1.701 0 3.62.935 4.5 2.25h3c1.505 0 3 1.495 3 3H8.25" />
              <path d="M14.52 15.75h4.98c2.719 0 3 1.5 3 1.5l-10.74 4.362a3.22 3.22 0 0 1-2.638-.11L3.75 18.774" />
              <path d="M18 12a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM3.75 10.5h-3v9.75h3V10.5ZM9 7.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
            </svg>
            <span>
              <Trans
                i18nKey={"common:hero.bestPrice"}
                components={[<strong />]}
              />
            </span>
          </>
        }
        image={
          <ImageCDN
            src={homepage.featuredImage.src}
            alt={brand.slogan}
            sizes={heroFullImageSizes}
          />
        }
      />
      <HomepageSectionDestinations
        assets={assets}
        destinations={destinations}
      />
      <HomepageSectionExperiences homepage={homepage} />
      {homepage.amenities && (
        <SectionAmenities
          label={t("amenities.label")}
          title={t("amenities.title", { brand: brand.name })}
          mainAmenities={homepage.amenities.map((amenity) => ({
            icon: <div dangerouslySetInnerHTML={{ __html: amenity.icon }} />,
            title: t(amenity.title),
          }))}
        />
      )}
      {hasReviews && (
        <SectionReviews
          label={t("reviews.label")}
          averageRating={brand.reviews?.averageRating}
          reviewCount={brand.reviews?.count}
          title={t("reviews.title", { brand: brand.name })}
          reviews={homepage.reviews}
        />
      )}
      <HomepageSectionBlog
        lastPosts={lastPosts}
        background={hasReviews ? "contrast" : "default"}
      />
      {brand.id === "libere" && (
        <SectionSpecialRates
          label={t("segments.label", { brand: brand.name })}
          title={t("segments.title")}
        />
      )}
      <BreadcrumbJsonLd
        itemListElements={[
          {
            position: 1,
            name: t("common:breadcrumbs.home"),
            item: `https://${brand.currentHost}/${locale}`,
          },
        ]}
      />
    </>
  );
};

export const getServerSideProps: GetServerSideProps =
  withDirectChannelCommonProps<HomePageProps>(async ({ req, locale }) => {
    const brand = req.context.brand;
    if (!locale || !brand.locales.includes(locale)) {
      return {
        notFound: true,
      };
    }

    const response = await fetchCms(
      `{
        destinations(where: {language: ${locale.toUpperCase()}, orderby: {field: DATE, order: ASC}}, first: 100) {
          nodes {
            title
            uri
            featuredImage {
              node {
                sourceUrl
              }
            }
            assets {
              properties {
                id
              }
            }
          }
        }
        assets(where: {language: ${locale.toUpperCase()}, orderby: {field: DATE, order: ASC}}) {
          nodes {
            title
            uri
            fullName
            properties {
              id
              closed
            }
            featuredImage {
              node {
                sourceUrl
              }
            }
          }
        }
        posts(where: {language: ${locale.toUpperCase()}}, first: 3) {
          nodes {
            title
            uri
            featuredImage {
              node {
                sourceUrl
              }
            }
          }
        }
      }`,
      brand.cmsUrl,
    );

    const destinations = (
      response.destinations as {
        nodes: Array<HomepageSectionDestinationsDestination>;
      }
    )?.nodes;
    const assets = (
      response.assets as { nodes: Array<HomepageSectionDestinationsAssets> }
    )?.nodes.filter((asset) => !asset.properties.closed);
    const lastPosts = (response.posts as { nodes: Array<PostResponse> })?.nodes;

    const homepageData = await homepage(brand.id, locale);

    if (!homepageData) {
      return { notFound: true };
    }

    return {
      props: {
        homepage: homepageData,
        trackingContext: {
          page_type: "home",
        },
        languageAlternates: req.context.brand.locales.map((locale) => ({
          href: `/${locale}`,
          hrefLang: locale,
        })),
        destinations,
        assets,
        lastPosts,
        page: {
          searchBar:
            brand.enabledFeatures?.includes("destinations") &&
            destinations.length > 0
              ? {
                  destinations: destinations
                    .map(({ title, uri }) => ({
                      title,
                      uri: `${uri}#assets`,
                    }))
                    .sort((a, b) => (a.title > b.title ? 1 : -1)),
                }
              : {
                  assets: assets
                    .map(({ title, uri }) => ({
                      title,
                      uri,
                    }))
                    .sort((a, b) => (a.title > b.title ? 1 : -1)),
                },
          isRootPage: true,
        },
      },
    };
  });

type HomePageProps = {
  homepage: Homepage;
  destinations: Array<HomepageSectionDestinationsDestination>;
  assets: Array<HomepageSectionDestinationsAssets>;
  lastPosts: Array<PostResponse>;
  trackingContext: TrackingContextPage;
  page: Page;
};

type PostResponse = {
  title: string;
  uri: string;
  featuredImage: {
    node: {
      sourceUrl: string;
    };
  };
};

export default HomepageView;
